import React from "react";
import Helmet from 'react-helmet';
import "../../src/assets/styles/Home.scss";
import PriceCard from "../components/PriceCard.js";
import FAQs from "../components/FAQs.js";
import Features from "../components/Features.js";
// import mainImg from "../../src/assets/images/main_img-min.svg";
import WebpImg from "../components/WebpImg";
import demoImg from "../../src/assets/images/demo_img-min.png";
import demoImgWebp from "../../src/assets/images/webp/demo_img_webp.webp";
import demoImgWebpMobile from "../../src/assets/images/webp/mobile/demo_img-min.png.small.webp";

import productivityImg from "../../src/assets/images/productivity-min.svg";
import productivityImgWebp from "../../src/assets/images/webp/productivity-min.webp";
import productivityImgWebpMobile from "../../src/assets/images/webp/mobile/productivity-min.png.small.webp";

import ideaImg from "../../src/assets/images/ideas-min.svg";
import ideaImgWebp from "../../src/assets/images/webp/ideas-min.webp";
import ideaImgWebpMobile from "../../src/assets/images/webp/mobile/ideas-min.png.small.webp";

import discussionImg from "../../src/assets/images/convo-min.svg";
import discussionImgWebp from "../../src/assets/images/webp/convo-min.webp";
import discussionImgWebpMobile from "../../src/assets/images/webp/mobile/convo-min.png.small.webp";


const Home = () => {
  return (
    <>
      <Helmet>
        <title>LaTeX for Slack</title>
        <meta name="description"
          content="LaTeX for Slack app allows you to embed beautifully formatted LaTeX equations directly in Slack. No system installations and works on web, desktop, and mobile." />
      </Helmet>
      <div id="homepage">
        <div className="intro">
          <h1>LaTeX for Slack</h1>
          <p className="subtitle">
            Share beautifully formatted equations directly in Slack.
          </p>
          <a id="mobile-slc-btn" href="https://slack.com/oauth/v2/authorize?client_id=2570488899698.2570501252898&amp;scope=commands&amp;user_scope=" className="slc-btn" target="_blank" rel="noreferrer"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
          <WebpImg
            webpMobileSrc={demoImgWebpMobile}
            webpSrc={demoImgWebp}
            imgSrc={demoImg}
            altText="demo LaTeX for Slack"
            imgClassName="demo-img"
          />
        </div>

        {/* <div className="how-it-works">
        <img
          src={mainImg}
          alt="discussion people"
          className="how-to-img"
        />
        <div className="how-to">
          <h2>How it works?</h2>
          <div className="how-to-points"><span>1.</span> Install Slack Bot</div>
          <div className="how-to-points"><span>2.</span> Start Free 14 day Trial</div>
          <div className="how-to-points"><span>3.</span> Type `/latex e=mc^2`</div>
        </div>
      </div> */}

        <Features />

        <PriceCard />

        <div className="promo">
          <h2>Focus on What Matters</h2>
          <p className="subtitle">
            Clearly communicate with properly formatted equations.
          </p>
          <div className="promo-grid">
            <div className="promo-card">
              <WebpImg
                webpMobileSrc={productivityImgWebpMobile}
                webpSrc={productivityImgWebp}
                imgSrc={productivityImg}
                altText="increase productivity with LaTeX for Slack "
                imgClassName="promo-img"
              />
              <p>Increase productivity</p>
            </div>
            <div className="promo-card">
              <WebpImg
                webpMobileSrc={ideaImgWebpMobile}
                webpSrc={ideaImgWebp}
                imgSrc={ideaImg}
                altText="share equations in LaTeX for Slack "
                imgClassName="promo-img"
              />
              <p>Share your ideas instantly</p>
            </div>
            <div className="promo-card">
              <WebpImg
                webpMobileSrc={discussionImgWebpMobile}
                webpSrc={discussionImgWebp}
                imgSrc={discussionImg}
                altText="collaborate in LaTeX for Slack "
                imgClassName="promo-img"
              />
              <p>Collaborate directly in Slack</p>
            </div>
          </div>
        </div>
        <FAQs />
      </div>
    </>
  );
};

export default Home;
