import React from 'react';
import '../../src/assets/styles/Menu.scss';
import logo from '../../src/assets/images/logo.svg';

const Menu = () => {
    return (
        <nav className="menu-container">
            <div className="menu">
                <a href="/" aria-label="logo"><img id="logo" alt="logo" src={logo} /></a>
                <ul className="menu-top">
                    <li><a href="/">Home</a></li>
                    <li><a href="/#features">Features</a></li>
                    <li><a href="/#pricing">Pricing</a></li>
                    <li><a href="/#faqs">FAQs</a></li>
                    <a href="https://slack.com/oauth/v2/authorize?client_id=2570488899698.2570501252898&amp;scope=commands&amp;user_scope=" className="slc-btn" target="_blank"  rel="noreferrer"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
                </ul>
            </div>

            <div className="mobile-menu">
                <a href="/" aria-label="logo"><img id="logo-mobile" src={logo} alt="logo" /></a>
                <input className="menu-btn" type="checkbox" id="menu-btn" />
                <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
                <ul className="menu" id="menu-1">
                    <li><a href="#home">Home</a></li>
                    <li><a href="#features">Features</a></li>
                    <li><a href="#pricing">Pricing</a></li>
                    <li><a href="#faqs">FAQs</a></li>
                </ul>
            </div>

        </nav>
    );
};

export default Menu;