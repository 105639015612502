import React from "react";
import WebpImg from "./WebpImg";
import "../assets/styles/Features.scss";

import jsonImg from "../../src/assets/images/json_save-min.png";
import jsonImgWebp from "../../src/assets/images/webp/json_save-min.webp";
import jsonImgWebpMobile from "../../src/assets/images/webp/mobile/json_save-min.png.small.webp";

import worksImg from "../../src/assets/images/works_on_every_device-min.png";
import worksImgWebp from "../../src/assets/images/webp/works_on_every_device-min.webp";
import worksImgWebpMobile from "../../src/assets/images/webp/mobile/works_on_every_device-min.png.small.webp";

import noInstallsImg from "../../src/assets/images/no_system_installs-min.png";
import noInstallsWebp from "../../src/assets/images/webp/no_system_installs-min.webp";
import noInstallsWebpMobile from "../../src/assets/images/webp/mobile/no_system_installs-min.png.small.webp";

import historyImg from "../../src/assets/images/history-min.png";
import historyWebp from "../../src/assets/images/webp/history-min.webp";
import historyWebpMobile from "../../src/assets/images/webp/mobile/history-min.png.small.webp";

import fastRendersImg from "../../src/assets/images/fast_renders-min.png";
import fastRendersWebp from "../../src/assets/images/webp/fast_renders-min.webp";
import fastRendersWebpMobile from "../../src/assets/images/webp/mobile/fast_renders-min.png.small.webp";


const Pricing = () => {
    return (
        <div id="features">
            <h2>Features</h2>

            <div id="features-box">
                <div className="feature">
                    <WebpImg
                        webpMobileSrc={worksImgWebpMobile}
                        webpSrc={worksImgWebp}
                        imgSrc={worksImg}
                        altText="LaTeX for Slack works for any device"
                        imgClassName="feature-img"
                    />
                    <p>Works on Web, Mobile, Desktop Apps</p>
                </div>
                <div className="feature">
                    <WebpImg
                        webpMobileSrc={noInstallsWebpMobile}
                        webpSrc={noInstallsWebp}
                        imgSrc={noInstallsImg}
                        altText="LaTeX for Slack needs no system installs"
                        imgClassName="feature-img"
                    />
                    <p>No system installs, works for everyone instantly</p>
                </div>
                <div className="feature">
                    <WebpImg
                        webpMobileSrc={historyWebpMobile}
                        webpSrc={historyWebp}
                        imgSrc={historyImg}
                        altText="LaTeX for Slack has history of equations"
                        imgClassName="feature-img"
                    />
                    <p>View history of equations for fast recall</p>
                </div>
                <div className="feature">
                    <WebpImg
                        webpMobileSrc={jsonImgWebpMobile}
                        webpSrc={jsonImgWebp}
                        imgSrc={jsonImg}
                        altText="Export equations to json in LaTeX for Slack"
                        imgClassName="feature-img"
                    />
                    <p>Export all rendered equations as json</p>
                </div>
                <div className="feature">
                    <WebpImg
                        webpMobileSrc={fastRendersWebpMobile}
                        webpSrc={fastRendersWebp}
                        imgSrc={fastRendersImg}
                        altText="Custom endpoint in LaTeX for Slack"
                        imgClassName="feature-img"
                    />

                    <p>Custom endpoint for very fast LaTeX renders</p>
                </div>
            </div>

        </div>
    );
};

export default Pricing;
