import React from "react";


const WebpImg = ({ webpSrc, webpMobileSrc, imgSrc, altText, imgClassName }) => {
    return (
        <picture>
            {webpMobileSrc && <source media="(max-width:500px)" srcSet={webpMobileSrc} type="image/webp" />}
            <source srcSet={webpSrc} type="image/webp" />
            <source srcSet={imgSrc} />
            <img
                src={imgSrc}
                alt={altText}
                className={imgClassName}
            />
        </picture>)
}
export default WebpImg;