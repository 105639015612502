import React from 'react';
import '../../src/assets/styles/Footer.scss';

const Footer = () => {
    return (
        <div id="footer">
            <div className="content">
                <div id="email">Email: support@latexforslack.com</div>
                <div className="links">
                    <a href="/privacy">Privacy Policy</a>
                    <a href="/terms">Terms of Service</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;