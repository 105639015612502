import React from "react";
import "../assets/styles/PriceCard.scss";

const Pricing = () => {
  return (
    <div id="pricing">
      <h2>Pricing</h2>
      <p className="subtitle">
        Our pricing is a simple flat rate for every Slack team. Render as many
        equations as you'd like.
      </p>
      <div className="price-card">
        <div className="price-title">One price for all</div>
        <div className="price bottom-bar">
          $5
          <small className="price-small">/month</small>
        </div>
        <div className="plan-pros bottom-bar">
          <span className="tick-mark"></span>Unlimited Usage
        </div>
        <div className="plan-pros bottom-bar">
          <span className="tick-mark"></span>One Price per Team
        </div>
        <div className="plan-pros bottom-bar">
          <span className="tick-mark"></span>Easy to Use
        </div>
        <div className="plan-pros bottom-bar">
          <span className="tick-mark"></span>14 day free trial
        </div>
        <div className="cta-btns">
          <a
            href="https://slack.com/oauth/v2/authorize?client_id=2570488899698.2570501252898&amp;scope=commands&amp;user_scope="
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
