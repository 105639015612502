import React from "react";
import "../../src/assets/styles/FAQs.scss";

const FAQs = () => {

  return (
    <div id="faqs">
      <h2>FAQs</h2>
      <p className="subtitle"></p>
      <div id="faq-box">
        <div className="q-a" >
          <div className="question">
            Does it work on all platforms?
          </div>
          <div className="answer">
            Yes! LaTeX for Slack works on web, mobile, and desktop apps.
          </div>
        </div>

        <div className="q-a" >
          <div className="question">
            Do individual users need to install anything?
          </div>
          <div className="answer">
            No individual installation. Once LaTeX for Slack is enabled for the
            Slack workspace, all members of the team can use the /latex command
            and view renders from other users.
          </div>
        </div>

        <div className="q-a" >
          <div className="question">
            Is the rendering fast?
          </div>
          <div className="answer">
            Yes, LaTeX for Slack uses a private rendering endpoint for very fast
            renders.
          </div>
        </div>

        <div className="q-a" >
          <div className="question">
            Can I export my data?
          </div>
          <div className="answer">
            Yes, at any time you can download a list of LaTeX snippets that have
            been rendered by your Slack team.
          </div>
        </div>

        <div className="q-a" >
          <div className="question"> 
            Can I view my previously rendered equations?
          </div>
          <div className="answer">
            Yes, you can view the last ten LaTeX snippets you rendered with the
            '/latex history' command.
          </div>
        </div>

        <div className="q-a" >
          <div className="question">
            How do I start?
          </div>
          <div className="answer">
            Click the 'Add to Slack' button. After confirming the trial, the
            `/latex` command will be available in your Slack workspace.
          </div>
        </div>

        <div className="q-a" >
          <div className="question">
            I have a question. Where can I contact
            you?
          </div>
          <div className="answer">
            We can be reached at support@latexforslack.com.
          </div>
        </div>

        <div className="q-a" >
          <div className="question">
            What is LaTeX?
          </div>
          <div className="answer">
            LaTeX is a markup language that makes it easy to share
            equations/formulas and other typeset structures.
          </div>
        </div>

        <div className="q-a" >
          <div className="question">
            How are you protecting my data?
          </div>
          <div className="answer">
            All data is transmitted and stored using commercial grade
            encryption. Our infrastructure lives in AWS and follows security
            best practices. All payment processing is handled through Stripe.
          </div>
        </div>

        <div className="q-a" >
          <div className="question">
            How do I cancel?
          </div>
          <div className="answer">
            Within the Slack App, click on "Billing Portal", and then click
            "Cancel Subscription". We are also available at
            support@latexforslack.com to help.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
