import Menu from '../src/components/Menu.js';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Footer from '../src/components/Footer.js';
import Home from '../src/pages/Home';
import Privacy from '../src/pages/Privacy';
import Terms from '../src/pages/Terms';

import './assets/styles/App.scss';

const App = () => {
  return (
    <div>
      <div className="content">
        <Menu />
        <BrowserRouter>
          <Switch>
            <Route path={`/privacy`} component={() => <Privacy />} />
            <Route path={`/terms`} component={() => <Terms />} />
            <Route path={`/`} component={() => <Home />} />
          </Switch>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
